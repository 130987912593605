<i18n src="@/i18n/misc.json"></i18n>
<i18n src="@/i18n/user.json"></i18n>

<template lang="pug">
    .auth-user-profile.view
        div(v-if="user_form.id")
            section
                h1 {{ user_form.name }}
                nav.auth-user-profile-tabs
                    router-link(v-for="(tab, tab_index) in tabs" :to="{name: 'user.profile.' + tab_index}" :key="tab_index") {{ tab.name }}
            section
                .form-container
                    div
                        el-form(:model="user_form", @keydown.enter.native.prevent="saveProfile", ref="user_form", :rules="user_form_rules", label-position="top", autocomplete="off")
                            el-form-item(prop="name", :label="$t('user.form.name')")
                                el-input(v-model="user_form.name", maxlength="24", show-word-limit)
                            el-form-item(prop="email", type="email", :label="$t('user.form.email')")
                                el-input(v-model="user_form.email", maxlength="24", show-word-limit)
                            el-form-item(prop="phone", :label="$t('user.form.phone')")
                                el-input(v-model="user_form.phone", maxlength="24")
                            el-form-item(v-if="user_form.want_change_password", prop="password", :label="$t('user.form.password')")
                                el-input(v-model="user_form.password", type="password", autocomplete="new-password")
                            el-button(v-if="!user_form.want_change_password", @click="user_form.want_change_password = !user_form.want_change_password", type="primary" plain) Change password
                            el-button(@click="saveMe", type="primary") {{ $t('misc.buttons.save') }}
                    .description
                        p A webform, web form or HTML form on a web page allows a user to enter data that is sent to a server for processing. Forms can resemble paper or database forms because web users fill out the forms using checkboxes, radio buttons, or text fields.


</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { GET_ME, SAVE_ME } from "@/store/types"

    import { default_auth_user } from '@/store/defaults'

    const default_user_form = ()=>{
        return {
            id: undefined,
            name: '',
            email: '',
            want_change_password: false,
        }
    }

    export default {
        name: "UserProfileMe",
        data () {
            return {
                current_tab: 'me',
                user_form: default_user_form(),
                user_form_rules: {
                    name: [
                        { required: true, message: this.$t('user.validation.name.required'), trigger: 'blur' },
                        { min: 2, max: 32, message: this.$t('user.validation.name.max'), trigger: 'blur' },
                    ],
                    email: [
                        { required: true, message: this.$t('user.validation.email.required'), trigger: 'blur' },
                        { type: 'email', message: this.$t('user.validation.email.email'), trigger: 'blur' },
                    ],
                },
            }
        },
        computed: {
            ...mapGetters([
                'auth_user',
            ]),
            tabs(){
                return this.$t('user.profile.tabs')
            },
        },
        mounted() {
            // Redirect to the login page if not logged in
            if(!this.auth_user.loggedIn()) this.$router.push({name: 'user.login'})

            this.getMe()
        },
        methods: {
            ...mapActions([
                GET_ME,
                SAVE_ME,
            ]),
            setUserForm(){
                // Map user data
                this.user_form = { ...default_user_form(), ...this.auth_user }
            },
            getMe(){
                return this.GET_ME().then(()=>{
                    this.setUserForm()
                }).catch((error)=>{
                    this.$root.pushAppMessages(error.response.data)
                })
            },
            saveMe(){
                this.$refs['user_form'].validate((valid) => {
                    if (valid) {
                        this.SAVE_ME(this.user_form)
                            .then(()=>{
                                this.setUserForm()

                                this.$root.pushAppMessages(this.$t('messages.saved'), 'success')
                            }).catch((error)=>{
                                this.$root.pushAppMessages(error.response.data)
                        })
                    } else return false
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .auth-user{
        &-profile{
            &-tabs{
                @include font--heading;
                @include font--medium;

                a{
                    display: inline-block;
                    margin: 0 2rem 0 0;

                    &:last-child{
                        margin-right: 0;
                    }

                    @media (max-width: $max-width--mobile){
                        display: block;
                        margin: 0 0 1rem 0;
                    }
                }
            }
        }
    }
</style>
